import React from 'react';
import saturday from './assets/saturday.jpg';
import Slider2 from './Slider2';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';

function SaturdaySatsang() {
  return (
    <div className="relative w-full">
      <Header />
      <img src={saturday} alt="Saturday Satsang" className="w-full h-auto" />
      
     
      <div className="absolute left-0 w-full top-0 mt-[79%]" >
        <Slider2 />
      </div>
      <Footer />
    </div>
  );
}

export default SaturdaySatsang;
