import React from 'react';
import bg from './assets/bg.jpg';
import ProgramSlider from './ProgramSlider';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';

function Program() {
  return (
    <div className="relative w-full">
      <Header />
      <img src={bg} alt="Saturday Satsang" className="w-full h-auto" />
      
      <div
        className="absolute top-0 mt-[121%] mx-[50%]"
        style={{      
          transform: 'translateX(-50%)',
          width: '80%',
        }}
      >
        <ProgramSlider />
      </div>
      <Footer />
    </div>
  );
}

export default Program;
