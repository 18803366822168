import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Page from "./assets/image/page.jpg";
import styles from "./SriLanka.module.css";

const SriLanka = () => {
    return (
        <>
          <Header/>
          <img src={Page} alt="" style={{ width: "100%"}} />
          <div className="d-flex justify-content-center" style={{ position: "absolute", marginTop : "-110px", width: "100%"}} >
            <NavLink to="https://rzp.io/rzp/G3pl2SC" style={{ width: "fit-content", textDecoration: "none"}}>
            <span className={styles.textSpan}>Register Now</span>
            </NavLink>
          </div>
          <Footer/>  
        </>
    )
}

export default SriLanka;