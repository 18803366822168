import React from 'react'
import { NavLink } from 'react-router-dom';
import "./styles.css"; 
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import upperPage from "./assests/lpm1.jpg"
import lowerPage from "./assests/lpm2.jpg"
import middlePage from "./assests/lpm3.png"

const lifeMembershipPage = () => {
  return (
    <>
      <Header />
      <img className='w-full' src={upperPage} alt="" /> 
      <NavLink to="https://rzp.io/rzp/0oDifSu">
      <img className='w-full' src={middlePage} alt="" />
      </NavLink>
      <img className='w-full' src={lowerPage} alt="" />
     <Footer />
    </>
  )
}

export default lifeMembershipPage;