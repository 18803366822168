import React, { useState, useEffect } from "react";

import image7 from "./assets/image7.jpg";
import image8 from "./assets/image8.jpg";
import image9 from "./assets/image9.jpg";
import image10 from "./assets/image10.jpg";
import image11 from "./assets/image11.jpg";
import image12 from "./assets/image12.jpg";
import bg from "./assets/bg.jpg";

const Slider2 = () => {
  const slides = [
    {
      images: [image7, image8],
      text: "Kirtan: Immerse Yourself in The joyful Sounds Of Kirtan, Whore We Sing The Glories Of Lord Krishna. This Collective Chanting Is A Powerful Way To Elevate Your Consciousness And Experience Divine Joy",
    },
    {
      images: [image9, image10],
      text: "Bhagavatam Class: Join Us For An Enlightening Session On The Srimad Bhagavatam. Where We Explore The Teachings And Stories Of Lord Krishna. Our Knowledgeable Speakers Will Share Insights That Inspire And Guide Us On Our Spiritual Journey.",
    },
    {
      images: [image11, image12],
      text: "Annadaan: Experience The Joy Of Giving As We Participate In Annadaan, The Distribution Of Food To All Attendees. Sharing Prasadam Is A Beautiful Way To Practice Compassion And Serve The Community.",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="slider-container bg-red-900 text-white p-8 text-center flex flex-col items-center justify-center relative"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
      }}
    >
      <h2
        className="text-[#F3B01B] mb-6"
        style={{
          fontWeight: 600,
          fontSize: "68px",
          fontFamily: "'Source Serif Pro', serif",
        }}
      >
        What To Expect
      </h2>

      <div className="relative flex items-center justify-center w-full max-w-4xl">
        <div
          className="main-slide flex flex-col items-center p-6 rounded-lg backdrop-blur-md"
          style={{
            width: "100%",
          }}
        >
          <div className="flex gap-[150px] mb-6 justify-center">
            {slides[currentSlide].images.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Slide ${currentSlide + 1} Image ${index + 1}`}
                className="object-cover rounded-lg shadow-lg"
                style={{
                  width: "450px",
                  height: "300px",
                  objectFit: "cover",
                  borderTop: "5px solid white",
                  borderBottom: "5px solid white",
                  borderLeft: "10px solid white",
                  borderRight: "10px solid white",
                }}
              />
            ))}
          </div>

          <div
            className="text-container flex items-center justify-center bg-opacity-75 rounded-lg"
            style={{
              marginTop: "20px",
              width: "100%",
              height: "100px",
              maxWidth: "2600px",
            }}
          >
            <p 
              className="text-center"
              style={{
                fontSize: "24px",
                fontWeight: "700",
                fontFamily: "'Open Sans', sans-serif",
                color: "#ffdebd"
              }}
            >
              <span style={{ color: "#F3B01B" }}>
                {slides[currentSlide].text.split(":")[0]}
              </span>
              :{slides[currentSlide].text.split(":")[1]}
            </p>
          </div>
        </div>
      </div>

      <button
        onClick={prevSlide}
        className="text-white rounded-full z-10 absolute left-0 transform -translate-y-1/3 flex items-center justify-center"
        style={{
          width: "150px",
          height: "150px",
          fontSize: "50px",
          right: "100px",
        }}
      >
        &#8249;
      </button>

      <button
        onClick={nextSlide}
        className="text-white rounded-full z-10 absolute right-0 transform -translate-y-1/3 flex items-center justify-center"
        style={{
          width: "150px",
          height: "150px",
          fontSize: "50px",
        }}
      >
        &#8250;
      </button>

      <div className="flex justify-center mt-4">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 rounded-full mx-1 ${
              index === currentSlide ? "bg-yellow-500" : "bg-gray-400"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slider2;
