import React from 'react'
import "./styles.css"; 
import bhakti1 from './assets/bhakti1.jpg';
import bhakti2 from './assets/bhakti2.jpg';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';

function BhaktiVriksha() {
  return (

    <div className=' w-full bg-[#ffecdb] '>
      <Header />
      <img src={bhakti1} alt="" />
      <img src={bhakti2} alt="" />
      <Footer />
    </div>
  )
}

export default BhaktiVriksha
