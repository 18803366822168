import React from 'react'
import "./styles.css"; 
import forum from './assets/forum1.jpg';
import forum2 from './assets/forum2.jpg';
import Footer from '../../../Components/Footer/Footer';
import Header from '../../../Components/Header/Header';

function YouthSeminar() {
  return (
    <div className='flex-col w-full'>
        <Header />
      <img src={forum} alt="" />
      <img src={forum2} alt="" />
      <Footer />
    </div>
  )
}

export default YouthSeminar
